module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kleiner Perkins - Innovators Network","short_name":"KP - Innovators Network","description":"Kleiner Perkins Innovators Network provides a bi-annual forum to collaborate with peers, learn from world-class industry leaders, and gain access to cutting edge startups driving today’s innovation.","lang":"en","display":"standalone","icon":"src/images/favicon@6x.png","start_url":"/","background_color":"#000","theme_color":"#fff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e4ea04c62342acbfc8d5fa293c4c3ab8"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
